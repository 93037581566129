import jwtDecode from "jwt-decode";
import { DecodedToken, Identity, PASSWORD_CHANGE_REQUIRED_CLAIM, TENANT_INFO_CLAIM, TenantInfo } from "../domain/user";

export const getIdentyFromToken = (token: string): Identity => {
  const decodedToken = jwtDecode<DecodedToken>(token);

  const permissions = decodedToken["permissions"] || [];
  const tenantInfo: TenantInfo = decodedToken[TENANT_INFO_CLAIM] || {};
  const passwordChangeRequired = decodedToken[PASSWORD_CHANGE_REQUIRED_CLAIM];

  return {
    platformUserId: decodedToken.sub,
    token,
    tenantInfo,
    passwordChangeRequired,
    permissions
  };
};
