import { useTranslation } from "react-i18next";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import { useAuth, useClient } from "../context/auth-context";
import { useTenantContext } from "../context/tenant-context";
import { useToast } from "../context/toast-context";
import { Department, Tenant } from "../domain/company";
import { TenantDepartmentsResponse } from "../domain/departments";

import { PostData } from "@talkouttech/portal-shared";
import { useMemo } from "react";
import {
  ActiveServices,
  BulkDeleteTenantUsersPayload,
  BulkInviteTenantUsersPayload,
  BulkUpdateTenantUsersPayload,
  BulkUpdateUserPayload,
  DeleteTenantUserPayload,
  InvitationStatus,
  ROLES,
  TenantUserPayload,
  TenantUserResponse,
  TenantsServicesRepsonse,
  UserProfileCourses,
  UserProfileSummary
} from "../domain/user";
import { CourseAssignmentStatusResult } from "../domain/v2/course";
import usePermissions from "../hooks/usePermissions";
import assignParamsToUrl from "../utils/assignParamsToUrl";
import { delay } from "../utils/helpers";
import { useApiVersion, useFeaturesQuery } from "./features";
import { buildPostsQueryParams } from "./utils/buildPostsQueryParams";
import { filterUserOrgDep, filterUserStatusNotOnBoarded, filterUserStatusOnBoarded } from "./utils/filterUserStatus";

const defaultProfileState: UserProfileSummary = {
  interests: [""],
  aboutMe: "",
  aboutRole: "",
  id: "",
  displayName: "",
  firstName: "",
  lastName: "",
  avatarUrl: "",
  departmentId: "",
  departmentName: "",
  jobRole: "",
  organisationId: "",
  organisationName: "",
  isSuspended: false,
  stats: {
    followers: 0,
    following: 0,
    posts: 0,
    kudos: 0,
    reactions: 0
  },
  services: []
};

const currentDate = new Date().toISOString();

function useTenants(includeSuspended?: boolean, includeDeleted?: boolean) {
  const client = useClient<Tenant[]>();
  const { identity } = useAuth();

  // We need to invalidate the query when the user changes (i.e. during impersonation)
  const platformUserId = identity?.platformUserId;

  const {
    data: tenants,
    isLoading,
    refetch
  } = useQuery<Tenant[], Error>({
    queryKey: [`tenants`, platformUserId, !!includeSuspended, !!includeDeleted],
    queryFn: () =>
      client(
        `tenants?${includeSuspended ? "$includeSuspended=true" : ""}${includeDeleted ? "&$includeDeleted=true" : ""}`
      ).then(data => data)
  });

  return { tenants: tenants ?? [], isLoading, refetch };
}

function useFilteredTenants(filterValue: string) {
  const { tenants, isLoading } = useTenants();

  const filtered = useMemo(
    () =>
      tenants.filter(elem => {
        const filterWord = filterValue.toLowerCase();
        const filter = elem.name.toLowerCase().includes(filterWord) || elem.key.toLowerCase().includes(filterWord);

        return filter;
      }),
    [tenants, filterValue]
  );

  const sorted = useMemo(() => {
    const source = filterValue.length > 1 ? filtered : tenants;
    return source.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }, [filtered, filterValue, tenants]);

  return { tenants: sorted, isLoading };
}

function useUserDepartments(tenant: string) {
  const client = useClient<Department[]>();

  const { data: departments, isLoading } = useQuery<Department[], Error>({
    queryKey: `tenant-departments-${tenant}`,
    queryFn: () => client(`tenants/${tenant}/departments`).then(data => data)
  });
  return { departments: departments ?? [], isLoading };
}

/** Fetch data for User Profile Summary section, Posts card */
const useUserPosts = ({
  tenant: tenantId,
  userId
}: {
  tenant: string;
  userId: string;
  onError?: (error: string, other?: {}) => void;
  onSuccess?: () => void;
}) => {
  const client = useClient<PostData[]>();
  const { showSnackBar } = useToast();

  const { data, isLoading } = useQuery<PostData[], Error>({
    queryKey: `tenants-${tenantId}-user-${userId}-profile-summary-posts`,
    queryFn: () =>
      client(
        assignParamsToUrl(`tenants/:tenantId/profiles/:userId/posts?from=${currentDate}&limit=5`, {
          tenantId,
          userId
        })
      )
        .then(data => data)
        .catch(_ => []),
    onError: e => {
      if (showSnackBar && e) {
        showSnackBar("Failed to get user posts information", undefined, "error");
      }
    }
  });

  return { data: data ?? [], isLoading };
};

const useUserKudosPosts = ({
  tenant: tenantId,
  userId
}: {
  tenant: string;
  userId: string;
  onError?: (error: string, other?: {}) => void;
  onSuccess?: () => void;
}) => {
  const client = useClient<PostData[]>();
  const { showSnackBar } = useToast();

  const { data, isLoading } = useQuery<PostData[], Error>({
    queryKey: [tenantId, userId, "user-kudos"],
    queryFn: () =>
      client(
        assignParamsToUrl(`tenants/:tenantId/profiles/:userId/kudos/posts?$limit=5`, {
          tenantId,
          userId
        })
      )
        .then(data => data)
        .catch(_ => []),
    onError: e => {
      if (showSnackBar && e) {
        showSnackBar("Failed to get user kudos posts information", undefined, "error");
      }
    }
  });

  return { data: data ?? [], isLoading };
};

/** Fetch data for User Profile Summary section, PostsModal */
const useUserInfiniteKudosPosts = ({
  tenant: tenantId,
  userId,
  limit = 5
}: {
  tenant: string;
  userId: string;
  limit?: number;
  onError?: (error: string, other?: {}) => void;
  onSuccess?: () => void;
}) => {
  const client = useClient<PostData[]>();
  const { showSnackBar } = useToast();

  const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery<PostData[], Error>({
    queryKey: `tenants-${tenantId}-user-${userId}-profile-summary-kudos-posts-infinite`,
    queryFn: ({ pageParam }) =>
      client(
        assignParamsToUrl(
          `tenants/:tenantId/profiles/:userId/kudos/posts?_createdOn=<${pageParam || currentDate}&$limit=${limit}`,
          {
            tenantId,
            userId
          }
        )
      )
        .then(data => data)
        .catch(_ => []),
    onError: e => {
      if (showSnackBar && e) {
        showSnackBar("Failed to get user kudos posts information", undefined, "error");
      }
    },
    getNextPageParam: lastPage => lastPage[limit - 1]?._createdOn
  });
  const pages = data?.pages.reduce((acc, ele) => [...acc, ...ele], []);

  return { data: pages ?? [], isLoading, fetchNextPage, hasNextPage };
};

const useUserInfinitePosts = ({
  tenant: tenantId,
  order = "",
  userId,
  limit = 5,
  showMandatory = false,
  showResource = false,
  date
}: {
  tenant: string;
  order?: "asc" | "desc" | "";
  userId: string;
  limit?: number;
  showMandatory?: boolean;
  showResource?: boolean;
  onError?: (error: string, other?: {}) => void;
  onSuccess?: () => void;
  date: string;
}) => {
  const client = useClient<PostData[]>();
  const { showSnackBar } = useToast();

  const { data, isLoading, fetchNextPage, hasNextPage, isError, refetch } = useInfiniteQuery<PostData[], Error>({
    queryKey: `tenants-${tenantId}-user-${userId}-profile-summary-posts-infinite-${showMandatory}-${showResource}-${order}`,
    queryFn: ({ pageParam }) => {
      return client(
        `tenants/${tenantId}/profiles/${userId}/posts?limit=${limit}${buildPostsQueryParams({
          order,
          showMandatory,
          showResource
        })}${
          order === "asc"
            ? pageParam === undefined
              ? ""
              : `&_createdOn=>${pageParam}`
            : `&_createdOn=<${pageParam || date}`
        }`
      )
        .then(data => data)
        .catch(_ => []);
    },
    onError: e => {
      if (showSnackBar && e) {
        showSnackBar("Failed to get more posts!", undefined, "error");
      }
    },
    getNextPageParam: lastPage => lastPage[limit - 1]?._createdOn
  });
  const pages = data?.pages.reduce((acc, ele) => [...acc, ...ele], []);

  return { data: pages ?? [], isLoading, fetchNextPage, hasNextPage, isError, refetch };
};

const useCurrentUserProfile = (tenantOverride?: string) => {
  const { identity } = useAuth();
  const client = useClient<UserProfileSummary>();
  const { apiVersion } = useApiVersion();
  const { activeTenant } = useTenantContext();
  const tenant = tenantOverride ?? activeTenant;
  const isStaff = identity?.permissions?.includes("tenant:admin-read");
  const id =
    (tenant && identity?.tenantInfo[tenant]?.organisationUserId) ??
    (isStaff ? "00000000-0000-0000-0000-000000000000" : undefined);
  const token = identity?.token;

  const { data, isLoading } = useQuery<UserProfileSummary, Error>({
    enabled: !!id && !!token && !!tenant && !!apiVersion,
    queryKey: [`user-profile`, id, tenant],
    queryFn: () => client(`tenants/${tenant}/profiles/${id}/full`).then(data => data)
  });

  return { currentProfile: data ?? defaultProfileState, isLoading };
};

/** Fetch data for User Profile Summary section, main card */
const useUserProfile = (params: {
  userId: string;
  onError?: (error: string, other?: {}) => void;
  onSuccess?: () => void;
  waitFor?: any;
}) => {
  const client = useClient<UserProfileSummary>();
  const { showSnackBar } = useToast();
  const { activeTenant: tenant } = useTenantContext();
  const { apiVersion } = useApiVersion();

  const { data, isLoading, isSuccess } = useQuery<UserProfileSummary, Error>({
    queryKey: [tenant, params.userId, `profile-summary-main`],
    enabled: params.userId.length > 0 && params.waitFor !== undefined && !!apiVersion,
    queryFn: () =>
      client(assignParamsToUrl("tenants/:tenantId/profiles/:userId/full", { tenantId: tenant, userId: params.userId }))
        .then(data => data)
        .catch(err => err),
    onError: e => {
      if (showSnackBar && e) {
        showSnackBar(`userProfileInfoFailed`, undefined, "error");
      }
    }
  });

  return { data: data ?? null, isLoading, isSuccess };
};

function useUserServices(tenant: string) {
  const client = useClient<TenantsServicesRepsonse>();

  const { data: services, isLoading } = useQuery<ActiveServices, Error>({
    queryKey: `tenant-${tenant}-services`,
    queryFn: () => client(`features?level=context&tenantId=${tenant}`).then(data => data.activeServices)
  });

  return { services: services || {}, isServicesLoading: isLoading };
}

const useStructureUsers = ({
  tenant,
  id,
  type = "organisation"
}: {
  tenant: string;
  id: string;
  type?: "department" | "organisation";
}) => {
  const client = useClient<TenantUserResponse[]>();
  const { showSnackBar } = useToast();

  const { data: users, isLoading } = useQuery<TenantUserResponse[], Error>({
    queryKey: `tenants-${tenant}-users-${id}`,
    queryFn: () => client(`tenants/${tenant}/users?${type}Id=${id}`).then(data => data),
    onError: e => {
      if (showSnackBar && e) {
        showSnackBar(`Failed to fetch users for specific ${type}!`, undefined, "error");
      }
    }
  });

  return { users: users ?? [], isLoading };
};

function useOrganisationsUsers(params: {
  onError?: (error: string, other?: {}) => void;
  onSuccess?: () => void;
  canManage?: boolean;
}) {
  const client = useClient<TenantUserResponse[]>();
  const { showSnackBar } = useToast();
  const { activeTenant: tenant } = useTenantContext();

  const {
    data: users,
    isLoading,
    refetch
  } = useQuery<TenantUserResponse[], Error>({
    queryKey: `tenants-${tenant}-users`,
    queryFn: () =>
      client(
        params.canManage ? `tenants/${tenant}/users?$canManage=${params.canManage}` : `tenants/${tenant}/users`
      ).then(data => data),
    onError: e => {
      if (showSnackBar && e) {
        showSnackBar(`failedFetchUsers`, undefined, "error");
      }
    }
  });

  return { users: users ?? [], isLoading, refetch };
}

function useOrganisationUserRoleOptions() {
  const {
    featuresConfig: {
      enableMultipleOrganisations,
      flags: { isManagerRoleEnabled }
    }
  } = useFeaturesQuery();

  const { isTenantAdmin } = usePermissions();

  return useMemo(() => {
    return ROLES.filter(role => {
      switch (role) {
        case "admin":
          return isTenantAdmin;
        case "orgAdmin":
          return enableMultipleOrganisations;
        case "manager":
          return !!isManagerRoleEnabled;
        default:
          return true;
      }
    });
  }, [enableMultipleOrganisations, isManagerRoleEnabled, isTenantAdmin]);
}

function useOrganisationsUsersWithFilter(params: {
  tenant: string;
  filter: string;
  status: string;
  selectedOrganisationIds?: string[];
  selectedDepartmentIds?: string[];
  limitToDisplayName?: boolean;
  canManage?: boolean;
  selectedRows?: string[];
  integrationId?: string;
  onError?: (error: string, other?: {}) => void;
}) {
  const { users, isLoading, refetch } = useOrganisationsUsers({
    onError: params.onError || undefined,
    canManage: params.canManage
  });

  const notInvitedUsers = useMemo(
    () => filterUserStatusOnBoarded({ users, status: InvitationStatus.none }) || [],
    [users]
  );

  const userListByType = useMemo(() => {
    const pendingInvitedUsers = filterUserStatusOnBoarded({ users, status: InvitationStatus.pending }) || [];
    const onboardedUsers =
      filterUserStatusNotOnBoarded({ users, status: InvitationStatus.accepted, isOnboardingComplete: true }) ?? [];
    const onboardedInProgressUsers =
      filterUserStatusNotOnBoarded({
        users,
        status: InvitationStatus.accepted,
        isOnboardingComplete: false
      }) ?? [];
    return [
      { key: "", value: users },
      { key: InvitationStatus.none, value: notInvitedUsers },
      { key: InvitationStatus.pending, value: pendingInvitedUsers },
      { key: InvitationStatus.accepted, value: onboardedUsers },
      { key: InvitationStatus.onboardedProgress, value: onboardedInProgressUsers }
    ];
  }, [notInvitedUsers, users]);

  const filtered = useMemo(() => {
    if (
      !params.filter &&
      !params.integrationId &&
      (!params.filter || params.filter.length < 3) &&
      !params.selectedDepartmentIds?.length &&
      !params.selectedOrganisationIds?.length &&
      !params.selectedRows?.length &&
      !params.status
    ) {
      return users;
    }

    return userListByType
      .flatMap(item => {
        if (item.key === params.status) {
          return item.value;
        }
        return [];
      })
      .filter(item => !params.integrationId || item.sourceIntegrationId === params.integrationId)
      .filter(item => {
        if (!params.selectedOrganisationIds || params.selectedOrganisationIds.length < 1) return true;
        return filterUserOrgDep({ user: item, selectedOrganisationIds: params.selectedOrganisationIds });
      })
      .filter(item => {
        if (!params.selectedDepartmentIds || params.selectedDepartmentIds.length < 1) return true;
        return filterUserOrgDep({ user: item, selectedDepartmentIds: params.selectedDepartmentIds });
      })
      .filter(elem => {
        const filterWord = params.filter?.toLowerCase();
        let filter;
        if (params.limitToDisplayName && elem.displayName && !params.selectedRows)
          filter = elem.displayName.toLowerCase().includes(filterWord);
        else if (elem.displayName && elem.organisationName && !params.selectedRows)
          filter =
            elem.displayName.toLowerCase().includes(filterWord) ||
            elem.firstName.toLowerCase().includes(filterWord) ||
            elem.lastName.toLowerCase().includes(filterWord) ||
            elem.organisationName.toLowerCase().includes(filterWord) ||
            elem.jobRole?.toLowerCase().includes(filterWord) ||
            elem.email?.toLowerCase().includes(filterWord) ||
            elem.phoneNumber?.toLowerCase().includes(filterWord) ||
            elem.role.toLowerCase().includes(filterWord);
        else if (elem.displayName && elem.organisationName && params.selectedRows)
          filter =
            elem.displayName.toLowerCase().includes(filterWord) ||
            elem.firstName.toLowerCase().includes(filterWord) ||
            elem.lastName.toLowerCase().includes(filterWord) ||
            elem.organisationName.toLowerCase().includes(filterWord) ||
            elem.jobRole?.toLowerCase().includes(filterWord) ||
            elem.email?.toLowerCase().includes(filterWord) ||
            elem.phoneNumber?.toLowerCase().includes(filterWord) ||
            elem.role.toLowerCase().includes(filterWord) ||
            params.selectedRows.includes(elem.id);
        return filter;
      });
  }, [
    params.filter,
    params.integrationId,
    params.limitToDisplayName,
    params.selectedDepartmentIds,
    params.selectedOrganisationIds,
    params.selectedRows,
    params.status,
    userListByType,
    users
  ]);
  return {
    users: filtered ?? [],
    allUsers: users,
    notInvitedUsers,
    isLoading,
    refetch
  };
}

function useUpdateOrganisationsUsers({
  successCallBack,
  isEditMode,
  userId
}: {
  successCallBack?: (user: Partial<TenantUserResponse>) => void;
  isEditMode: boolean;
  userId?: string;
}) {
  const client = useClient<TenantUserResponse>();
  const queryClient = useQueryClient();
  const { showSnackBar } = useToast();

  const mutation = useMutation<TenantUserResponse, Error, TenantUserPayload>(
    updates => {
      const command = isEditMode
        ? {
            firstName: updates.firstName,
            lastName: updates.lastName,
            displayName: updates.displayName,
            email: updates.email,
            phoneNumber: updates.phoneNumber,
            role: updates.role,
            departmentId: updates.department.id,
            organisationId: updates.organisation.id,
            services: updates.services,
            jobRole: updates.jobRole,
            startDate: updates.startDate || undefined,
            terminationDate: updates.terminationDate || undefined,
            customFields: updates.customFields
          }
        : {
            firstName: updates.firstName,
            lastName: updates.lastName,
            displayName: updates.displayName,
            email: updates.email,
            phoneNumber: updates.phoneNumber,
            role: updates.role,
            departmentId: updates.department.id,
            services: updates.services,
            jobRole: updates.jobRole,
            startDate: updates.startDate || undefined,
            terminationDate: updates.terminationDate || undefined,
            customFields: updates.customFields
          };

      return client(
        `tenants/${updates.tenant}/organisations/${updates.organisation.id}/users${isEditMode ? `/${userId}` : ""}`,
        {
          method: isEditMode ? "PUT" : "POST",
          data: command
        }
      );
    },
    {
      onSuccess: (result, variables) => {
        const newUser = {
          ...result,
          organisationName: variables.organisation.name,
          departmentName: variables.department.name
        } as TenantUserResponse;

        if (isEditMode) {
          queryClient.setQueryData(`tenants-${variables.tenant}-users`, (old: TenantUserResponse[] | undefined) => {
            return [...(old as TenantUserResponse[]).map(user => (user.id === result.id ? newUser : user))];
          });
        } else {
          queryClient.setQueryData(`tenants-${variables.tenant}-users`, (old: TenantUserResponse[] | undefined) => {
            return [...(old as TenantUserResponse[]), newUser];
          });
        }

        if (successCallBack) {
          successCallBack(newUser);
        }

        if (showSnackBar) {
          showSnackBar(isEditMode ? "User edited" : "User added");
        }
      }
    }
  );
  return mutation;
}

function useUpdateDepartmentInfo({
  successCallBack,
  departmentId,
  organisationId
}: {
  successCallBack: () => void;
  departmentId?: string;
  organisationId: string;
}) {
  const client = useClient<TenantDepartmentsResponse>();
  const queryClient = useQueryClient();
  const { showSnackBar } = useToast();
  const { activeTenant } = useTenantContext();

  const mutation = useMutation<unknown, Error, TenantDepartmentsResponse>(
    updates => {
      return client(`tenants/${activeTenant}/organisations/${organisationId}/departments/${departmentId}`, {
        method: "PUT",
        data: {
          description: updates.description,
          name: updates.name
        }
      });
    },
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries(`tenants-${activeTenant}-departments`);
        queryClient.invalidateQueries(`tenants-${activeTenant}-department-${departmentId}`);

        if (successCallBack) {
          successCallBack();
        }

        if (showSnackBar) {
          showSnackBar("Department edited");
        }
      }
    }
  );
  return mutation;
}

function useDeleteOrganisationsUser() {
  const client = useClient();
  const queryClient = useQueryClient();
  const { showSnackBar } = useToast();

  const mutation = useMutation<unknown, Error, DeleteTenantUserPayload>(
    updates =>
      client(`tenants/${updates.tenant}/organisations/${updates.organisation}/users/${updates.userId || ""}`, {
        method: "DELETE"
      }),
    {
      onSuccess: (_, variables) => {
        queryClient.setQueryData(`tenants-${variables.tenant}-users`, (old: any) => {
          return [...old].filter(elem => elem.id !== variables.userId || "");
        });

        if (showSnackBar) {
          showSnackBar(`${variables.name} removed`);
        }
      },
      onError: e => {
        if (showSnackBar && e) {
          showSnackBar(`Failed to delete user`, undefined, "error");
        }
      }
    }
  );

  return mutation;
}

function useDeleteBulkUsers() {
  const client = useClient();
  const queryClient = useQueryClient();
  const { showSnackBar } = useToast();

  const mutation = useMutation<unknown, Error, BulkDeleteTenantUsersPayload>(
    updates =>
      client(`tenants/${updates.tenant}/users/bulk/delete`, {
        method: "PUT",
        data: {
          ids: updates.usersIds
        }
      }),
    {
      onSuccess: (_, variables) => {
        queryClient.setQueryData(`tenants-${variables.tenant}-users`, (old: any) => {
          return [...old].filter(elem => !variables.usersIds.includes(elem.id));
        });

        if (showSnackBar) {
          showSnackBar(`usersRemoved`);
        }
      },
      onError: e => {
        if (showSnackBar && e) {
          showSnackBar(`Failed to delete user`, "error");
        }
      }
    }
  );

  return mutation;
}

function useInviteBulkUsers() {
  const client = useClient();
  const queryClient = useQueryClient();
  const { activeTenant } = useTenantContext();
  const { showSnackBar } = useToast();

  const mutation = useMutation<unknown, Error, BulkInviteTenantUsersPayload>(
    updates =>
      client(`tenants/${activeTenant}/users/`, {
        method: "PATCH",
        data: updates.usersToInvite
      }),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(`tenants-${activeTenant}-users`);

        if (showSnackBar) {
          const isBulkInvite = variables.usersToInvite.length > 1;
          showSnackBar(isBulkInvite ? `usersInvited` : `userInvited`);
        }
      },
      onError: e => {
        if (showSnackBar && e) {
          showSnackBar(`failToInviteBulkUsers`, undefined, "error");
        }
      }
    }
  );

  return mutation;
}

function useEditBulkUsers({
  isRoleUpdate,
  isServiceUpdate,
  successCallBack
}: {
  isRoleUpdate?: boolean;
  isServiceUpdate?: boolean;
  successCallBack?: () => void;
}) {
  const client = useClient();
  const queryClient = useQueryClient();
  const { activeTenant } = useTenantContext();
  const { showSnackBar } = useToast();
  const { t } = useTranslation();

  const mutation = useMutation<unknown, Error, BulkUpdateTenantUsersPayload>(
    updates => {
      const data = updates.usersToUpdate.map(user => {
        if (isRoleUpdate) {
          return {
            id: user.id,
            role: user.role
          };
        } else if (isServiceUpdate) {
          return {
            id: user.id,
            services: user.services
          };
        } else {
          return {
            id: user.id,
            organisationId: user.organisation && user.organisation.id,
            departmentId: user.department && user.department.id
          };
        }
      });

      return client(`tenants/${activeTenant}/users/`, {
        method: "PATCH",
        data
      });
    },
    {
      onSuccess: (_, variables) => {
        const newUpdates = variables.usersToUpdate;
        const prevUsers: TenantUserResponse[] | undefined = queryClient.getQueryData(`tenants-${activeTenant}-users`);
        const mappedOldUsersToNewUpdatedProps = prevUsers!.map(user => {
          const match = newUpdates.find(u => u.id === user.id);
          if (!match) return user;
          if (isRoleUpdate) {
            return {
              ...user,
              role: match.role
            };
          }
          if (isServiceUpdate) {
            return {
              ...user,
              services: match.services
            };
          }
          return {
            ...user,
            departmentId: match.department?.id,
            organisationId: match.organisation?.id,
            organisationName: match.organisation?.name,
            departmentName: match.department?.name
          };
        });

        queryClient.setQueryData(`tenants-${activeTenant}-users`, () => {
          return mappedOldUsersToNewUpdatedProps;
        });

        if (successCallBack) {
          successCallBack();
        }

        if (showSnackBar) {
          showSnackBar(
            `youEditedDataOf`,
            `${variables.usersToUpdate.length} ${variables.usersToUpdate.length === 1 ? t("user") : t("users")}`
          );
        }
      },
      onError: e => {
        if (showSnackBar && e) {
          showSnackBar(`failToEditBulkUsers`, undefined, "error");
        }
      }
    }
  );

  return mutation;
}

function useOrganisationPhotoUpload(type: "avatar" | "coverimage", callback?: () => void) {
  const client = useClient();
  const queryClient = useQueryClient();
  const { showSnackBar } = useToast();

  const mutation = useMutation<any, Error, { tenant: string; formData: FormData; id: string }>(
    updates =>
      client(`tenants/${updates.tenant}/organisations/${updates.id}/${type}`, {
        method: "PUT",
        data: updates.formData,
        formData: true
      }),
    {
      onSuccess: async (_, variables) => {
        callback && callback();
        queryClient.invalidateQueries(`tenant-${variables.tenant}`);
        showSnackBar && showSnackBar(`Organisation photo set!`);
      },
      onError: _ => showSnackBar && showSnackBar(`Failed to upload organisation photo`, undefined, "error")
    }
  );

  return mutation;
}

function useOrganisationPhotoDelete(type: "avatar" | "coverimage", callback?: () => void) {
  const client = useClient();
  const queryClient = useQueryClient();
  const { showSnackBar } = useToast();

  const mutation = useMutation<any, Error, { tenant: string; id: string }>(
    updates =>
      client(`tenants/${updates.tenant}/organisations/${updates.id}/${type}`, {
        method: "DELETE"
      }),
    {
      onSuccess: async (_, variables) => {
        callback && callback();
        queryClient.invalidateQueries(`tenant-${variables.tenant}`);
        showSnackBar && showSnackBar(`Organisation photo deleted!`);
      },
      onError: _ => showSnackBar && showSnackBar(`Failed to delete organisation photo`, undefined, "error")
    }
  );

  return mutation;
}

function useDepartmentPhotoUpload(type: "avatar" | "coverimage", callback?: () => void) {
  const client = useClient();
  const queryClient = useQueryClient();
  const { showSnackBar } = useToast();

  const mutation = useMutation<
    any,
    Error,
    { tenant: string; formData: FormData; organisationId: string; departmentId: string }
  >(
    updates =>
      client(
        `tenants/${updates.tenant}/organisations/${updates.organisationId}/departments/${updates.departmentId}/${type}`,
        {
          method: "PUT",
          data: updates.formData,
          formData: true
        }
      ),
    {
      onSuccess: async (_, variables) => {
        callback && callback();
        queryClient.invalidateQueries(`tenants-${variables.tenant}-department-${variables.departmentId}`);
        showSnackBar && showSnackBar(`Department photo set!`);
      },
      onError: _ => showSnackBar && showSnackBar(`Failed to upload department photo`, undefined, "error")
    }
  );

  return mutation;
}

function useDepartmentPhotoDelete(type: "avatar" | "coverimage", callback?: () => void) {
  const client = useClient();
  const queryClient = useQueryClient();
  const { showSnackBar } = useToast();

  const mutation = useMutation<any, Error, { tenant: string; organisationId: string; departmentId: string }>(
    updates =>
      client(
        `tenants/${updates.tenant}/organisations/${updates.organisationId}/departments/${updates.departmentId}/${type}`,
        { method: "DELETE" }
      ),
    {
      onSuccess: async (_, variables) => {
        callback && callback();
        queryClient.invalidateQueries(`tenants-${variables.tenant}-department-${variables.departmentId}`);
        showSnackBar && showSnackBar(`Department photo deleted!`);
      },
      onError: _ => showSnackBar && showSnackBar(`Failed to delete department photo`, undefined, "error")
    }
  );

  return mutation;
}

function useBulkUserImport(successCallBack?: () => void) {
  const client = useClient();
  const queryClient = useQueryClient();
  const { showSnackBar } = useToast();

  const mutation = useMutation<any, Error, BulkUpdateUserPayload>(
    updates =>
      client(`tenants/${updates.tenant}/users/csv`, {
        method: "POST",
        data: updates.formData,
        formData: true
      }),
    {
      onSuccess: async ({ created, updated }: { created: number; updated: number }, variables) => {
        // We don't have websocket yet and backend has some delay, so we need to "hack" it a little with 1sec delay before query invalidation.
        await delay(1000);

        queryClient.invalidateQueries(`tenants-${variables.tenant}-users`);

        if (successCallBack) {
          successCallBack();
        }

        if (!showSnackBar) return;
        let message = "";

        if (created > 0) message += `${created} new user${created > 1 ? "s are" : " is"} created. `;
        if (updated > 0) message += `${updated} existing user${updated > 1 ? "s are" : " is"} updated.`;

        showSnackBar(message);
      },
      onError: e => {
        if (!showSnackBar) return;
        if (Array.isArray(e) && e.length > 0) showSnackBar(`Failed: ${e[0].message}`, undefined, "error");
        else showSnackBar(`Failed to bulk update user`, undefined, "error");
      }
    }
  );

  return mutation;
}

function useResendUserInvitation() {
  const client = useClient();
  const { showSnackBar } = useToast();

  return useMutation<unknown, Error, { tenant: string; invitationId: string }>(
    updates =>
      client(
        assignParamsToUrl(`tenants/:tenant/invitations/:invitationId/resend`, {
          tenant: updates.tenant,
          invitationId: updates.invitationId
        }),
        {
          method: "PUT"
        }
      ),
    {
      onSuccess: () => {
        showSnackBar && showSnackBar("We’ve just resent the invitation e-mail", undefined, "success");
      },
      onError: e => {
        if (showSnackBar && e) {
          showSnackBar("Failed to resent the invitation e-mail", undefined, "error");
        }
      }
    }
  );
}

function useSuspendedUsers() {
  const client = useClient<TenantUserResponse[]>();
  const { activeTenant } = useTenantContext();
  const { data: suspendedUsers, isLoading } = useQuery<TenantUserResponse[], Error>({
    queryKey: `tenant-departments-${activeTenant}-suspended`,
    queryFn: () => client(`tenants/${activeTenant}/users?isSuspended=true`).then(data => data)
  });
  return { suspendedUsers: suspendedUsers ?? [], isLoading };
}

function useDepartmentMembers(departmentId: string) {
  const client = useClient<TenantUserResponse[]>();
  const { activeTenant } = useTenantContext();
  const { data: members, isLoading } = useQuery<TenantUserResponse[], Error>({
    enabled: departmentId !== undefined,
    queryKey: `tenant-departments-${activeTenant}-department-${departmentId}-members`,
    queryFn: () => client(`tenants/${activeTenant}/users?departmentId=${departmentId}`).then(data => data)
  });
  return { members: members ?? [], isLoading };
}

const useSuspendUser = () => {
  const client = useClient<{ organisationId: string; userId: string; isSuspended: boolean }>();
  const queryClient = useQueryClient();
  const { activeTenant } = useTenantContext();
  const { showSnackBar } = useToast();

  const mutation = useMutation<unknown, Error, { organisationId: string; userId: string; isSuspended: boolean }>(
    updates =>
      client(`tenants/${activeTenant}/organisations/${updates.organisationId}/users/${updates.userId}`, {
        method: "PATCH",
        data: {
          isSuspended: updates.isSuspended
        }
      }),
    {
      onSuccess: (_, payload) => {
        queryClient.invalidateQueries(`tenant-departments-${activeTenant}-suspended`);
        queryClient.invalidateQueries(`tenants-${activeTenant}-user-${payload.userId}-profile-summary-main`);
        showSnackBar && showSnackBar(payload.isSuspended ? "userSuspended" : "userUnSuspended", undefined, "success");
      },
      onError: _ => {
        showSnackBar && showSnackBar("cantSuspendUser", undefined, "error");
      }
    }
  );

  return mutation;
};

function useUserProfileCourses(organisationUserId: string) {
  const client = useClient<UserProfileCourses[]>();
  const { activeTenant } = useTenantContext();

  const { apiVersion } = useApiVersion();

  const {
    data: userCourses,
    isLoading,
    refetch
  } = useQuery<UserProfileCourses[], Error>({
    queryKey: [`useProfileCourses`, activeTenant, organisationUserId, apiVersion],
    queryFn: () =>
      client(
        `tenants/${activeTenant}/profiles/${organisationUserId}/${apiVersion === 1 ? "courses" : "courseinstances"}`
      ).then(data => data)
  });
  return { userCourses: userCourses ?? [], isLoading, refetch };
}

function useUserProfileCoursesWithFilter(searchedWord: string, filters: string[], organisationUserId: string) {
  const { userCourses, isLoading, refetch } = useUserProfileCourses(organisationUserId);

  const filteredCourses = userCourses
    .filter(({ name }) => {
      return (name ?? "").toLowerCase().includes(searchedWord.toLowerCase());
    })
    .filter(({ status }) => {
      if (!filters.length) return true;
      return filters.includes(status);
    });

  if (searchedWord.length > 2 || filters.length > 0) {
    return { userCourses: filteredCourses, isLoading, refetch };
  }
  return { userCourses, isLoading, refetch };
}

function useUserAssignedCourses(organisationUserId: string) {
  const client = useClient<CourseAssignmentStatusResult[]>();
  const { activeTenant } = useTenantContext();

  const { data, isLoading, refetch } = useQuery<CourseAssignmentStatusResult[], Error>({
    queryKey: [`tenants-${activeTenant}-profiles-${organisationUserId}-assignedcourses`],
    queryFn: () => client(`tenants/${activeTenant}/profiles/${organisationUserId}/assignedcourses`).then(data => data)
  });

  return { data: data ?? [], isLoading, refetch };
}

interface UserAssignedCoursesWithFilter {
  courseName: string;
  statuses: string[];
}

function useUserAssignedCoursesWithFilter(filters: UserAssignedCoursesWithFilter, organisationUserId: string) {
  const { data, isLoading, refetch } = useUserAssignedCourses(organisationUserId);

  const filteredCourses = data
    .filter(({ courseName }) => {
      return (courseName ?? "").toLowerCase().includes(filters.courseName.toLowerCase());
    })
    .filter(({ status }) => {
      if (!filters.statuses.length) return true;
      return filters.statuses.includes(status ?? "");
    });

  if (filters.courseName.length > 2 || filters.statuses.length > 0) {
    return { data: filteredCourses, isLoading, refetch };
  }

  return { data: filteredCourses, isLoading, refetch };
}

interface SetPasswordCommand {
  id: string;
  password: string;
}

function useSetPasswordForOrganisationUser() {
  const client = useClient<void>();
  const { activeTenant } = useTenantContext();
  const { showSnackBar } = useToast();

  return useMutation<void, Error, SetPasswordCommand>(
    command =>
      client(`tenants/${activeTenant}/users/${command.id}/password`, {
        method: "PUT",
        data: {
          password: command.password
        }
      }),
    {
      onSuccess: () => showSnackBar?.("passwordUpdated")
    }
  );
}

interface ChangePasswordCommand {
  oldPassword: string;
  password: string;
}

function useChangePassword() {
  const client = useClient<Response>();
  const { showSnackBar } = useToast();
  const mutation = useMutation<void, Error, ChangePasswordCommand>(
    data =>
      client(`auth/password`, {
        method: "PATCH",
        data,
        returnRawResponse: true
      }).then(response => {
        if (response.ok) {
          return;
        }
        if (response.status === 403) {
          throw new Error("Unauthorised");
        }
        console.log(response);
        throw new Error("Unknown");
      }),
    {
      onSuccess: () => {
        showSnackBar?.("passwordUpdated");
      },
      onError: error => {
        if (error.message === "Unauthorised") {
          showSnackBar?.("oldPasswordInvalid", undefined, "error");
        }
      }
    }
  );

  return mutation;
}

export {
  useBulkUserImport,
  useChangePassword,
  useCurrentUserProfile,
  useDeleteBulkUsers,
  useDeleteOrganisationsUser,
  useDepartmentMembers,
  useDepartmentPhotoDelete,
  useDepartmentPhotoUpload,
  useEditBulkUsers,
  useFilteredTenants,
  useInviteBulkUsers,
  useOrganisationPhotoDelete,
  useOrganisationPhotoUpload,
  useOrganisationUserRoleOptions,
  useOrganisationsUsers,
  useOrganisationsUsersWithFilter,
  useResendUserInvitation,
  useSetPasswordForOrganisationUser,
  useStructureUsers,
  useSuspendUser,
  useSuspendedUsers,
  useTenants,
  useUpdateDepartmentInfo,
  useUpdateOrganisationsUsers,
  useUserAssignedCourses,
  useUserAssignedCoursesWithFilter,
  useUserDepartments,
  useUserInfiniteKudosPosts,
  useUserInfinitePosts,
  useUserKudosPosts,
  useUserPosts,
  useUserProfile,
  useUserProfileCourses,
  useUserProfileCoursesWithFilter,
  useUserServices
};
