import { Loader } from "@talkouttech/portal-shared";
import { FC, lazy, useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useRedirectAfterAuthUrl } from "./application/auth/auth";
import { PasswordChangeRequired } from "./components/PasswordChangeRequired";
import { useAuth } from "./context/auth-context";

const AuthenticatedApp = lazy(() => import(/* webpackPrefetch: true */ "./presentation/AuthenticatedApp"));
const AuthFlow = lazy(() => import(/* webpackPrefetch: true */ "./presentation/auth-flow-page"));
const InviteAppWrapper = lazy(() => import(/* webpackPrefetch: true */ "./presentation/InviteAppWrapper"));

const PrivateContent: FC = ({ children }) => {
  const { isAuthenticated, isLoading, identity } = useAuth();
  const history = useHistory();
  const [, setRedirectUrl] = useRedirectAfterAuthUrl();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      setRedirectUrl(window.location.pathname);
      history.push("/login");
    }
  }, [isAuthenticated, history, isLoading, setRedirectUrl]);

  if (isLoading) {
    return <Loader open />;
  }

  if (identity?.passwordChangeRequired) {
    return <PasswordChangeRequired />;
  }

  return isAuthenticated ? <>{children}</> : null;
};

const App = () => (
  <Switch>
    <Route path="/healthcheck" render={() => <p>OK</p>} />
    <Route path="/invite" render={() => <InviteAppWrapper />} />
    <Route path="/login" render={() => <AuthFlow />} />
    <Route
      render={() => (
        <PrivateContent>
          <AuthenticatedApp />
        </PrivateContent>
      )}
    />
  </Switch>
);

export default App;
