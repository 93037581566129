import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Drawer } from "@mui/material";
import { drawerWidth, theme } from "@talkouttech/portal-shared";

export const NavWrapper = styled.nav`
  @media (min-width: ${theme.breakpoints.values.md}px) {
    width: 65px;
    flex-shrink: 0;
  }

  @media (min-width: ${theme.breakpoints.values.lg}px) {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

export const StyledDrawer = styled(Drawer)`
  & .MuiDrawer-paper {
    width: ${drawerWidth}px;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: 0;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const openStyles = css`
  width: ${drawerWidth}px;
  overflow-x: hidden;
`;

const closedStyles = css`
  overflow-x: hidden;
  width: calc(${theme.spacing(7)} + 1px);
  transition: ${theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  })};

  @media (min-width: ${theme.breakpoints.values.md}px) {
    width: calc(${theme.spacing(9)} + 1px);
  }
`;

export const StyledDrawerLarge = styled(Drawer)<{ open: boolean; backgroundColor: string }>`
  & .MuiDrawer-paper {
    width: ${drawerWidth}px;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: 0;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    background-color: ${({ backgroundColor }) => backgroundColor};

    ${({ open }) => (open ? openStyles : closedStyles)}
  }
`;

export const AppLogo = styled.img`
  max-width: 60%;
  max-height: 100%;
`;
