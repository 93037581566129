import { Box, Typography } from "@mui/material";
import { colors, theme } from "@talkouttech/portal-shared";
import { FC, ReactElement, useState } from "react";
import { MdClose, MdError, MdInfo, MdWarning } from "react-icons/md";
import { StyledAppBox } from "./InfoBox.styles";

interface Props {
  children: ReactElement;
  iconSize?: number;
  removeMargin?: boolean;
  textVariant?: "body1" | "body2" | "caption" | "semiBoldBody1";
  closeable?: boolean;
  fullWidth?: boolean;
  type?: "info" | "warning" | "error";
}

export const InfoBox: FC<Props> = ({
  children,
  removeMargin = false,
  textVariant,
  iconSize = 48,
  closeable,
  fullWidth,
  type = "info"
}) => {
  const [visible, setVisible] = useState(true);

  return visible ? (
    <StyledAppBox
      removeMargin={removeMargin}
      variant="infoBoxSecondary"
      width={fullWidth ? "100%" : undefined}
      style={
        type === "warning"
          ? { backgroundColor: colors.orange }
          : type === "error"
          ? { backgroundColor: colors.error }
          : {}
      }
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
        <Box display="flex" alignItems="center">
          {type === "info" && <MdInfo size={iconSize} color={theme.colors.white} />}
          {type === "warning" && <MdWarning size={iconSize} color={theme.colors.white} />}
          {type === "error" && <MdError size={iconSize} color={theme.colors.white} />}
          <Typography
            variant={textVariant ?? "caption"}
            component="p"
            ml={1}
            color={theme.colors.white}
            fontWeight={500}
          >
            {children}
          </Typography>
        </Box>
        {closeable && (
          <Box display="flex" alignItems="center">
            <MdClose onClick={() => setVisible(false)} color={theme.colors.white} size={20} />
          </Box>
        )}
      </Box>
    </StyledAppBox>
  ) : null;
};
