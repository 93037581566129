import { InvitationStatus, TenantUserResponse } from "../../domain/user";

export const filterUserStatusOnBoarded = ({
  users,
  status
}: {
  users: TenantUserResponse[];
  status: InvitationStatus;
}) => {
  return users.filter(item => item.invitationStatus === status);
};

export const filterUserStatusNotOnBoarded = ({
  users,
  status,
  isOnboardingComplete
}: {
  users: TenantUserResponse[];
  status: InvitationStatus;
  isOnboardingComplete: boolean;
}) => {
  return users.filter(item => item.invitationStatus === status && item.isOnboardingComplete === isOnboardingComplete);
};

export const filterUserOrgDep = ({
  user,
  selectedOrganisationIds,
  selectedDepartmentIds
}: {
  user: TenantUserResponse;
  selectedOrganisationIds?: string[];
  selectedDepartmentIds?: string[];
}) => {
  if (selectedOrganisationIds && selectedOrganisationIds.length) {
    return selectedOrganisationIds.includes(user.organisationId);
  } else if (selectedDepartmentIds && selectedDepartmentIds.length) {
    return selectedDepartmentIds.includes(user.departmentId);
  } else {
    return user;
  }
};
