import { useMemo } from "react";
import { useAuth } from "../context/auth-context";
import { useTenantContext } from "../context/tenant-context";

/**
 * Checks permissions to write for specific admin user
 */
const usePermissions = () => {
  const { identity } = useAuth();
  const { activeTenant } = useTenantContext();
  const effectivePermissions = useMemo(() => {
    if (!identity) {
      return [];
    }

    const tenantPermissions = (activeTenant && identity?.tenantInfo[activeTenant]?.permissions) || [];

    return Array.from(new Set([...identity.permissions, ...tenantPermissions]));
  }, [identity, activeTenant]);

  const canAddUsers = effectivePermissions.includes("organisationuser:write-all");
  const canCreateCourses = effectivePermissions.includes("course:write");
  const canManageTenantCourses = effectivePermissions.includes("course:write-tenant");
  const canManagePlatformCourses = effectivePermissions.includes("course:write-all");
  const canModerate = effectivePermissions.includes("moderation:write");
  const canImpersonate = effectivePermissions.includes("platformuser:impersonate");
  const canManageMHFAStatus = effectivePermissions.includes("mhfa:admin");
  const canReadStats = effectivePermissions.includes("stat:read");
  const canManagePlatformPosts = effectivePermissions.includes("post:write-platform");
  const canCreateTenants = effectivePermissions.includes("tenant:admin-write");
  const canDestroyTenants = effectivePermissions.includes("tenant:admin-destroy");
  const isTenantAdmin = effectivePermissions.includes("tenant:write");
  const canViewAllTenants = effectivePermissions.includes("tenant:admin-read");
  const canDeleteAllPosts = effectivePermissions.includes("post:delete-all");

  return {
    canAddUsers,
    canModerate,
    canImpersonate,
    effectivePermissions,
    canManageMHFAStatus,
    canCreateCourses,
    canDestroyTenants,
    canManageTenantCourses,
    canManagePlatformCourses,
    canReadStats,
    canManagePlatformPosts,
    canCreateTenants,
    isTenantAdmin,
    canViewAllTenants,
    canDeleteAllPosts
  };
};

export default usePermissions;
