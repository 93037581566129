export function isValidPassword(password: string) {
  const chars = [...password];
  return (
    chars.length >= 8 &&
    chars.some(c => /[a-z]/.test(c)) &&
    chars.some(c => /[A-Z]/.test(c)) &&
    chars.some(c => /[0-9]/.test(c)) &&
    // eslint-disable-next-line no-useless-escape
    chars.some(c => /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(c))
  );
}
