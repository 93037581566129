import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAuth, useClient } from "../context/auth-context";
import { useTenantContext } from "../context/tenant-context";
import { Tenant } from "../domain/company";
import { delay } from "../utils/helpers";

export function useTenantDetails() {
  const { isAuthenticated, isLoading: isAuthenticating } = useAuth();
  const client = useClient<Tenant>();
  const { activeTenant } = useTenantContext();

  const { data, isLoading } = useQuery<Tenant, Error>({
    enabled: isAuthenticated,
    queryKey: ["tenant-details", activeTenant],
    queryFn: () => client(`tenants/${activeTenant}`)
  });

  return { data, isLoading: isLoading || isAuthenticating };
}

export function useGetTenantBranding() {
  const tenantDetails = useTenantDetails();

  const headerBackgroundColour = tenantDetails?.data?.brandConfig?.headerBackgroundColour ?? "#2A2532";
  const headerForegroundColour = tenantDetails?.data?.brandConfig?.headerForegroundColour ?? "#FFFFFF";
  const logoUrl = tenantDetails?.data?.brandConfig?.logoUrl;
  const inviteEmailBannerUrl = tenantDetails?.data?.brandConfig?.inviteEmailBannerUrl;
  const inviteEmailProductName = tenantDetails?.data?.brandConfig?.inviteEmailProductName;

  return {
    headerBackgroundColour,
    headerForegroundColour,
    logoUrl,
    inviteEmailBannerUrl,
    inviteEmailProductName
  };
}

export enum BrandingFileType {
  Logo = "logo",
  InviteEmailBanner = "inviteEmailBanner"
}

interface ApplyTenantBrandingCommand {
  headerBackgroundColour?: string;
  headerForegroundColour?: string;
  logoFile?: File | null;
  inviteEmailBannerFile?: File | null;
  inviteEmailProductName?: string;
}

export function useApplyTenantBranding() {
  const client = useClient();
  const { activeTenant } = useTenantContext();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation<unknown, Error, ApplyTenantBrandingCommand>(
    command => {
      const formData = new FormData();
      const fileMapping: BrandingFileType[] = [];
      if (!!command.logoFile) {
        fileMapping.push(BrandingFileType.Logo);
      }
      if (!!command.inviteEmailBannerFile) {
        fileMapping.push(BrandingFileType.InviteEmailBanner);
      }

      const data: any = {
        headerBackgroundColour: command.headerBackgroundColour,
        headerForegroundColour: command.headerForegroundColour,
        inviteEmailProductName: command.inviteEmailProductName
      };
      if (fileMapping.length > 0) {
        data.fileMappings = fileMapping;
      }

      formData.append("data", JSON.stringify(data));

      if (!!command.logoFile) {
        formData.append("files", command.logoFile);
      }
      if (!!command.inviteEmailBannerFile) {
        formData.append("files", command.inviteEmailBannerFile);
      }

      return client(`tenants/${activeTenant}/branding`, {
        method: "PUT",
        data: formData,
        formData: true
      });
    },
    {
      onSuccess: _ => {
        delay(1000).then(() => queryClient.invalidateQueries(["tenant-details", activeTenant]));
      }
    }
  );

  return { mutate, isLoading };
}

export function useResetTenantBranding() {
  const client = useClient();
  const { activeTenant } = useTenantContext();
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    () => {
      return client(`tenants/${activeTenant}/branding`, {
        method: "DELETE"
      });
    },
    {
      onSuccess: _ => {
        delay(1000).then(() => queryClient.invalidateQueries(["tenant-details", activeTenant]));
      }
    }
  );

  return { mutate, isLoading: false };
}
