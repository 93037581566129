export function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function reorder<T>(list: T[], startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function handleDisplayNumberOfPostStatsItems(number: number): string {
  if (number > 999) {
    return `${number / 1000}k`;
  } else {
    return `${number}`;
  }
}
