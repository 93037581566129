import { Order } from "../../domain/post";

export const buildPostsQueryParams = ({
  order,
  showMandatory,
  showResource
}: {
  order: Order | "";
  showMandatory: boolean;
  showResource: boolean;
}) => {
  let url = "";

  if (order) url += `&$sort=_createdOn|${order}`;
  if (showMandatory) url += `&isMandatory=true`;
  if (showResource) url += `&isResource=true`;

  return url;
};

export const buildDeleteBulkUsersQueryParams = ({
  propertyName = "id",
  ids
}: {
  propertyName?: string;
  ids: string[];
}) => {
  let url = ``;

  if (ids.length < 1) return `?${propertyName}=`;

  ids.forEach((id, index) => {
    url += `${index === 0 ? "?" : "&"}${propertyName}=${id}`;
  });

  return url;
};
