import { Box, TextField } from "@mui/material";
import { AppButton } from "@talkouttech/portal-shared";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { isValidPassword } from "../../application/passwordUtils";
import { useChangePassword } from "../../application/users";
import { useAuth } from "../../context/auth-context";
import { InfoBox } from "../InfoBox";
import { TenantLogo } from "../TenantLogo/TenantLogo";
import { Container } from "./PasswordChangeRequired.styles";

export const PasswordChangeRequired: FC = () => {
  const { t } = useTranslation();
  const { refreshAuth0TokenWithoutCache } = useAuth();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { mutate, isLoading } = useChangePassword();

  const handleChangePassword = () => {
    if (newPassword !== confirmPassword) {
      return;
    }

    mutate(
      {
        oldPassword: currentPassword,
        password: newPassword
      },
      {
        onSuccess: () => {
          refreshAuth0TokenWithoutCache?.();
        }
      }
    );
  };

  const isValid = useMemo(() => {
    if (currentPassword.length < 5) {
      return false;
    }

    return isValidPassword(newPassword) && newPassword === confirmPassword;
  }, [currentPassword, confirmPassword, newPassword]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" pt={10} gap={2}>
      <Container display="flex" flexDirection="column" alignItems="center" gap={2} p={4} maxWidth={400}>
        <Box display="flex" width={300} justifyContent="center">
          <TenantLogo dark />
        </Box>
        <InfoBox iconSize={20} type="warning">
          {t("changePassword.forceChangeWarning")}
        </InfoBox>

        <TextField
          required
          type="password"
          value={currentPassword}
          onChange={e => setCurrentPassword(e.target.value)}
          label={t("changePassword.currentPassword")}
          error={currentPassword.length < 5}
          fullWidth
          sx={{ mt: 2 }}
        />
        <TextField
          required
          type="password"
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
          label={t("changePassword.newPassword")}
          error={!isValidPassword(newPassword)}
          fullWidth
        />
        <TextField
          required
          type="password"
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
          label={t("changePassword.confirmNewPassword")}
          error={!isValidPassword(confirmPassword) || confirmPassword !== newPassword}
          fullWidth
        />

        <AppButton fullWidth sx={{ mt: 2 }} onClick={handleChangePassword} disabled={isLoading || !isValid}>
          {t("changePassword.changePassword")}
        </AppButton>
      </Container>
    </Box>
  );
};
