import { useGetTenantBranding } from "../../application/tenantDetails";
import logo from "../../assets/LogoWhite.svg";
import darkLogo from "../../assets/logo.svg";
import { AppLogo } from "../AppDrawer/AppDrawer.styles";

interface TenantLogoProps {
  dark?: boolean;
}

const TenantLogo: React.FC<TenantLogoProps> = ({ dark = false }) => {
  const { logoUrl } = useGetTenantBranding();
  return <AppLogo src={logoUrl ? logoUrl : dark ? darkLogo : logo} alt="Reelyze logo" />;
};

export { TenantLogo };
