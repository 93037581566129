type ParamValue = number | string | boolean | null | undefined;

/**
 * Assign params to url
 *
 * @example
 * const url = '/users/:userId';
 * const params = { userId: 69 };
 * assignParamsToUrl(url, params);
 * // returns '/users/69';
 *
 * @param {string} url with parameters to assign
 * @param {Record<string, ParamValue>} params
 * @returns {string} url with assigned parameters
 */
const assignParamsToUrl = <T extends string = string>(url: T, params: Record<string, ParamValue> = {}): T => {
  for (const param in params) {
    if (!Object.prototype.hasOwnProperty.call(params, param)) continue;
    const value = params[param] ?? "";
    const regex = new RegExp(`:${param}`, "g");

    url = url.replace(regex, `${value}`) as T;
  }

  if (process.env.NODE_ENV === "development") {
    if (Object.values(params).some(value => typeof value === "undefined" || value === null))
      console.debug("Params contain undefined or null values. They are assigned as an empty string", { params, url });
  }

  return url;
};

export default assignParamsToUrl;
